<template>
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 33 32"
  >
    <path
      d="m24.5 8-16 16M8.5 8l16 16"
      stroke="#133949"
      stroke-opacity=".8"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
