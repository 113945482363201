<script lang="ts" setup>
defineProps<{
  icon: Component;
  title: string;
  description: string;
  hideCloseButton?: boolean;
}>();

const emit = defineEmits<{
  (event: "close"): void;
}>();
</script>

<template>
  <div>
    <div
      v-auto-animate
      class="mt-6 flex flex-col items-center justify-center gap-6"
    >
      <div class="w-fit rounded-full bg-green-500/10 p-7">
        <component
          :is="icon"
          class="size-11 shrink-0"
        />
      </div>

      <p class="text-center text-xl font-semibold text-text-title">
        {{ title }}
      </p>
      <p class="text-center text-base text-text-title/50">
        {{ description }}
      </p>
    </div>

    <UButton
      v-if="!hideCloseButton"
      class="ring-gray-200 absolute right-4 top-4 rounded-full p-2"
      variant="outline"
      color="gray"
      @click.prevent="emit('close')"
    >
      <IconCross class="size-5" />
    </UButton>
  </div>
</template>
