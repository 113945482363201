<script lang="ts" setup>
defineProps<{
  size?: "normal" | "flexible" | "compact";
  appearance?: "always" | "execute" | "interaction-only";
}>();

const token = defineModel<string>({
  default: "",
});

const isHidden = ref(true);

const { inputId, name } = useFormGroup();

const turnstileRef = ref<{ reset: () => void; }>();
defineExpose({
  resetToken: turnstileRef.value?.reset,
});

onMounted(async () => {
  // Wait for 3 seconds before showing the captcha to prevent showing
  // unnecessary empty space when challenge is not required.
  await new Promise(resolve => setTimeout(resolve, 3000));
  if (!token.value) {
    isHidden.value = false;
  }

  watch(token, async (value) => {
    if (value) {
      // Wait for 1.5 seconds before hiding the captcha to show the
      // user they've passed the challenge.
      await new Promise(resolve => setTimeout(resolve, 1500));
      isHidden.value = true;
    }
    else {
      isHidden.value = false;
    }
  }, { immediate: true });
});
</script>

<template>
  <Transition>
    <NuxtTurnstile
      v-show="!isHidden"
      :id="inputId"
      ref="turnstileRef"
      v-model="token"
      :name="name"
      :options="{
        size: size ?? 'flexible',
        appearance: appearance ?? 'interaction-only',
        theme: 'light',
      }"
    />
  </Transition>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
